<template>
  <v-container class="content">
    <v-row>
      <v-col align="center">
        <h1 class="mb-8">Sign Up</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="6" offset-sm="3" lg="8" offset-lg="2">
        <v-form ref="form" @submit.prevent="submit">
          <v-row>
            <v-col cols="6">
              <v-text-field
                  v-model="signup.firstName"
                  name="firstName"
                  type="text"
                  label="First Name"
                  outlined
                  dense
                  placeholder=" "
                  persistent-placeholder
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                  v-model="signup.lastName"
                  name="lastName"
                  type="text"
                  label="Last Name"
                  outlined
                  dense
                  placeholder=" "
                  persistent-placeholder
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                  v-model="signup.email"
                  name="email"
                  type="text"
                  label="Email"
                  outlined
                  dense
                  placeholder=" "
                  persistent-placeholder
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                  v-model="signup.password"
                  name="password"
                  type="text"
                  label="Password"
                  outlined
                  dense
                  placeholder=" "
                  persistent-placeholder
              ></v-text-field>
            </v-col>
          </v-row>
          <br/>
          <v-divider></v-divider>
          <br/>
          <v-row>
            <v-col cols="6">
              <v-text-field
                  v-model="signup.company.name"
                  name="companyName"
                  type="text"
                  label="Company Name"
                  outlined
                  dense
                  placeholder=" "
                  persistent-placeholder
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                  v-model="signup.company.license"
                  name="license"
                  type="text"
                  label="ABC License #"
                  outlined
                  dense
                  placeholder=" "
                  persistent-placeholder
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  v-model="signup.company.address"
                  name="address"
                  type="text"
                  label="Address"
                  outlined
                  dense
                  placeholder=" "
                  persistent-placeholder
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                  v-model="signup.company.address2"
                  name="address2"
                  type="text"
                  label="Extended Address"
                  outlined
                  dense
                  placeholder=" "
                  persistent-placeholder
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  v-model="signup.company.city"
                  name="city"
                  type="text"
                  label="City"
                  outlined
                  dense
                  placeholder=" "
                  persistent-placeholder
              ></v-text-field>
            </v-col>
            <v-col>
              <v-select
                  :items="states"
                  v-model="signup.company.state"
                  name="state"
                  type="text"
                  label="State"
                  outlined
                  dense
                  placeholder=" "
                  persistent-placeholder
              ></v-select>
            </v-col>
            <v-col>
              <v-text-field
                  v-model="signup.company.zipcode"
                  name="zipcode"
                  type="number"
                  label="Zipcode"
                  outlined
                  dense
                  placeholder=" "
                  persistent-placeholder
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <v-btn
                  block
                  color="primary"
                  :loading="loading"
                  type="submit"
              >
                <v-icon left light>mdi-account-plus</v-icon>
                Sign Up
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  components: {},
  name: "SignupView",
  metaInfo: {
    title: "Sign Up",
  },
  data() {
    return {
      signup: {
        company: {}
      },
      loading: false,
      states: [
        'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut',
        'Delaware', 'District of Columbia', 'Florida', 'Georgia', 'Hawaii', 'Idaho',
        'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland',
        'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada',
        'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio',
        'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee',
        'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
      ],
    };
  },
  methods: {
    submit() {
      this.loading = true;
      axios
          .post("/api/signup", this.signup)
          .then(() => {
            this.$store.dispatch('banner/open', {text: 'Thank you for signing up! We will be in contact shortly.'})
            this.$refs.form.reset();
          })
          .catch((r) => {
            let message = r.response.data.message
            if (!message) {
              if (r.response.data.errors) {
                let firstError = r.response.data.errors[0]
                message = (firstError.field ? firstError.field : '') + ' ' + firstError.message
              }
            }
            if (message) {
              this.$store.dispatch('banner/error', {text: message, dismissable: false})
              this.signup.password = null
              this.$refs.password.focus()
            } else {
              this.$store.dispatch('banner/error', {
                text: 'An unknown error occurred. Please try again later.',
                dismissable: false
              })
            }
          })
          .finally(() => {
            this.loading = false;
          });
    },
  },
};
</script>

<style scoped>
</style>
